import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import * as slugify from "slugify";
import * as uuid4 from "uuid4";

import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { MatToolbarModule } from "@angular/material/toolbar";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatListModule } from "@angular/material/list";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatMenuModule } from "@angular/material/menu";
import { MatDividerModule } from "@angular/material/divider";
import { MatChipsModule } from "@angular/material/chips";
import { MatSelectModule } from "@angular/material/select";
import { MatRadioModule } from "@angular/material/radio";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSnackBarModule } from "@angular/material/snack-bar";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CreateTaskDialog } from "./dialogs/create-task.dialog";
import { AboutAppDialog } from "./dialogs/about.dialog";
import { RelaxDialog } from "./dialogs/relax.dialog";

import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { boardsReducer } from "./board/board.reducer";
import { BoardEffects } from "./board/board.effects";
import { BoardComponent } from "./board/board.component";
import { ShareAppDialog } from "./dialogs/share.dialog";
import { ShareService } from "./board/share.service";
import { CreateBoardDialog } from "./dialogs/create-board.dialog";
import { SettingsComponent } from "./settings/settings.component";
import { settingsReducer } from "./settings/settings.reducer";
import { SnackbarEffects } from "./snackbar.effects";
import { SettingsEffects } from "./settings/settings.effects";
import { SearchComponent } from "./search/search.component";
import { WikiComponent } from "./wiki/wiki.component";
import { CalendarComponent } from "./calendar/calendar.component";
import { ReportsComponent } from "./reports/reports.component";

@NgModule({
  declarations: [
    AppComponent,
    CreateTaskDialog,
    AboutAppDialog,
    ShareAppDialog,
    BoardComponent,
    SettingsComponent,
    RelaxDialog,
    CreateBoardDialog,
    SearchComponent,
    WikiComponent,
    CalendarComponent,
    ReportsComponent,
  ],
  entryComponents: [
    CreateTaskDialog,
    AboutAppDialog,
    ShareAppDialog,
    RelaxDialog,
    CreateBoardDialog,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatGridListModule,
    MatListModule,
    MatCardModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    DragDropModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatDividerModule,
    MatChipsModule,
    MatSelectModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatSnackBarModule,
    StoreModule.forRoot({ boards: boardsReducer, settings: settingsReducer }),
    EffectsModule.forRoot([BoardEffects, SnackbarEffects, SettingsEffects]),
  ],
  providers: [
    { provide: "slugify", useValue: slugify },
    { provide: "window", useValue: window },
    { provide: "uuid4", useValue: uuid4 },
    ShareService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
