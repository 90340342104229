import { v4 as uuid } from "uuid";
import { createReducer, on } from "@ngrx/store";
import {
  createBoard,
  createTask,
  createRow,
  updateTask,
  moveTask,
  removeTask,
  removeBoard,
} from "./board.actions";
import produce from "immer";
import * as _ from "lodash";
import { Board } from "./board.interface";

export const initalState: Board[] = [];

function clone(value) {
  return JSON.parse(JSON.stringify(value));
}

const _boardsReducer = createReducer(
  initalState,
  on(
    createBoard,
    produce((state, action) => {
      const board = Object.assign({ rows: [], id: uuid() }, action);
      state.push(board);
    })
  ),
  on(
    removeBoard,
    produce((state, action) => {
      return state.filter((board) => {
        return board.id !== action.boardId;
      });
    })
  ),
  on(
    createRow,
    produce((state, action) => {
      const board = state.find((board) => board.id === action.boardId);

      if (board) {
        board.rows = [
          ...(board.rows || []),
          { id: uuid(), tasks: [], ...action.row },
        ];
      }
    })
  ),
  on(
    createTask,
    produce((state, action) => {
      const index = state.findIndex((board) => board.id === action.boardId);

      if (index !== -1) {
        const board = state[index];
        const row = board.rows.find((row) => row.id === action.rowId);
        row.tasks = [{ points: "0", ...action.task }, ...(row.tasks || [])];
      }
    })
  ),
  on(
    updateTask,
    produce((state, action) => {
      const board = state.find((board) => board.id === action.boardId);

      if (board) {
        const row = board.rows.find((row) => row.id === action.rowId);
        const task = row.tasks.find((task) => task.id === action.task.id);
        Object.assign(task, action.task);
      }
    })
  ),
  on(
    removeTask,
    produce((state, action) => {
      const board = state.find((board) => board.id === action.boardId);

      if (board) {
        const row = board.rows.find((row) => row.id === action.rowId);
        const taskIndex = row.tasks.findIndex(
          (task) => task.id === action.taskId
        );
        row.tasks.splice(taskIndex, 1);
      }
    })
  ),
  on(
    moveTask,
    produce((state, action) => {
      const fromBoard = state.find((board) => board.id === action.from.boardId);
      const toBoard = state.find((board) => board.id === action.to.boardId);

      if (fromBoard && toBoard) {
        const fromRow = fromBoard.rows.find(
          (row) => row.id === action.from.rowId
        );

        const task = fromRow.tasks.find((task) => task.id === action.taskId);

        const taskIndex = fromRow.tasks.findIndex((_task) => _task === task);
        fromRow.tasks.splice(taskIndex, 1);

        const toRow = toBoard.rows.find((row) => row.id === action.to.rowId);

        Object.assign(task, action.task, {
          row: action.to.rowId,
          board: action.to.boardId,
        });

        toRow.tasks.splice(action.to.index ? action.to.index : 0, 0, task);
      }
    })
  )
);

export function boardsReducer(state, action) {
  return _boardsReducer(state, action);
}
