import {
  AfterViewInit,
  Component,
  HostListener,
  ViewChild,
} from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import * as Moment from "moment";
import { extendMoment } from "moment-range";

const moment = extendMoment(Moment);

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
  disabled?: boolean;
  selected?: boolean;
}

@Component({
  selector: "app-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.scss"],
})
export class CalendarComponent implements AfterViewInit {
  public rows: number = 6;
  public rowHeight = this.getRowHeight();
  public tiles = this.getDisplayDays();

  public getRowHeight() {
    return `${(window.innerHeight - 69) / this.rows}px`;
  }

  public getDisplayDays(
    year = moment().format("YYYY"),
    month = moment().format("MM")
  ) {
    const _month = moment(`${year}-${month}`).format("YYYY-MM");
    const range = moment().range(
      moment(_month).startOf("month"),
      moment(_month).endOf("month")
    );

    const dayLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const days = [...range.by("days")].map((date) => ({
      day: date.format("ddd"),
      dayNum: date.format("DD"),
    }));

    const padStart = new Array(
      dayLabels.findIndex((label) => label === days[0].day)
    )
      .fill(null)
      .map((_, i) => i)
      .reverse()
      .map((i) => ({
        text: ``,
        disabled: true,
        cols: 1,
        rows: 1,
        isToday: false,
      }));

    const today = moment().format("DD");
    let calendar: any[] = padStart.concat(
      days.map((day) => ({
        text: day ? parseInt(day.dayNum, 10) : undefined,
        disabled: day === null,
        isToday: day && parseInt(today, 10) === parseInt(day.dayNum, 10),
        cols: 1,
        rows: 1,
      })) as any
    );

    const daysLeft = Math.round(Math.abs(this.rows - calendar.length / 7) * 7);

    calendar = calendar.concat(
      new Array(daysLeft).fill({ disabled: true, cols: 1, rows: 1 })
    );
    this.rowHeight = this.getRowHeight();

    return calendar;
  }

  @ViewChild("contextMenuTrigger", { static: true })
  public contextMenu: MatMenuTrigger;

  public contextMenuPosition = { x: "0px", y: "0px" };

  @HostListener("document:contextmenu", ["$event"])
  public preventContextMenu(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  @HostListener("window:resize", ["$event"])
  public windowResize(event: Event) {
    const window = event.target as Window;
    this.rowHeight = this.getRowHeight();
  }

  public ngAfterViewInit() {
    this.rowHeight = this.getRowHeight();
  }

  public openContextMenu(event: MouseEvent) {
    this.preventContextMenu(event);
    this.contextMenuPosition.x = event.clientX + "px";
    this.contextMenuPosition.y = event.clientY + "px";
    this.contextMenu.menuData = {};
    this.contextMenu.menu.focusFirstItem("mouse");
    this.contextMenu.openMenu();
  }
}
