import { Component, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import pkgJson from "../../../../../package.json";
import { environment } from "../../environments/environment";
import { NgForm } from "@angular/forms";
import { ShareService } from "../board/share.service";

export type DialogData = any;

@Component({
  selector: "dialog-share-app",
  styles: [],
  template: `
    <p>
      <strong
        >WARNING: This feature is not yet fully implemented. As of writing this
        will send the clear board command to the receiving board and then replay
        every command on the senders board. This means the commands stored will
        exponentioally increase with each transfer. Use as your own
        risk!</strong
      >
    </p>
    <p>Sync code: {{ shareService.id | async }}</p>

    <form #share="ngForm" (submit)="shareService.connect(share)">
      <mat-form-field>
        <mat-label>Recever's code</mat-label>
        <input ngModel name="code" matInput />
      </mat-form-field>
    </form>
    <p>Press enter after typing recever's code to send your board to them</p>
  `,
})
export class ShareAppDialog {
  public readonly environment = environment;
  public readonly pkgJson = pkgJson;

  @ViewChild("share", { static: true })
  public share: NgForm;

  constructor(
    public dialogRef: MatDialogRef<ShareAppDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public shareService: ShareService
  ) {}

  ngOnInit() {
    this.shareService.init(this.share);
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }
}
