import pkgJson from "../../../package.json";

/**
 * Migration Service
 *
 * Given the ever evolving nature of a progressive web application
 * we need a structured way to do single run operations on the data
 * which is persisted on a client system so to keep their models
 * up to date with those the application is consuming. To remidate
 * the problem we've created the Migration Service which is initallized
 * upon startup of the application.
 *
 * The init method of this service is run prior to bootstrapping of
 * the redux store.
 *
 * Migrations:
 *  - 0.0.0 -> 0.2.0 : Inital application
 *  - 0.2.0 -> 0.2.1 : Minor addition of optional properties
 *  - 0.2.1 -> 0.3.0 : Major state management rewrite (added and removed user actions)
 *  - 0.3.0 -> 0.3.1 : Minor alterations to action props
 */

export class MigrationService {
  /**
   * TODO: Only import migrations that are required.
   *
   * We only want to import that required migrations
   * to get the user's system to current. Each migration
   * is downloaded as a seperate file to the user's
   * system and applied in order based on this list.
   *
   * We should know at the time of initalization what
   * version the user is on and the list of available
   * migrations. We can then on the fly create a computed
   * list of migrations the user needs to be current.
   */
  static migrations = [
    import("./migrations/0.2.1.migration"),
    import("./migrations/0.3.0.migration"),
    import("./migrations/0.3.1.migration"),
  ];

  static async init() {
    // check if there is a version, if not then we are dealing
    // with a v0.2.0 client.
    let version = window.localStorage.getItem("workspace.version") || "0.2.0";

    // Because the migrations are listed as dynamic imports we need
    // to wait for them to complete before continuing. (They are a
    // promise based interface)
    const migrations = await Promise.all(this.migrations).then((modules) =>
      modules.map(({ migration }) => migration)
    );

    // We should only ever run the newer version migrations' up methods
    // so to bump our version up.
    migrations
      .filter(({ toVersion }) => toVersion > version)
      .map(({ up }) => up)
      .forEach((action) => action.call(this));

    // dump version to latest
    window.localStorage.setItem("workspace.version", pkgJson.version);
  }
}
