import { Component } from "@angular/core";
import { BoardEffects } from "./board/board.effects";
import { MatDialog } from "@angular/material/dialog";
import { AboutAppDialog } from "./dialogs/about.dialog";
import { ShareAppDialog } from "./dialogs/share.dialog";
import { RelaxDialog } from "./dialogs/relax.dialog";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  constructor(
    public dialog: MatDialog,
    public boardService: BoardEffects,
    private title: Title
  ) {}

  public ngOnInit() {
    if (window.location.host.includes("localhost")) {
      this.title.setTitle("Task Helper (Local)");
    }
  }

  public openShare() {
    const data = {};
    this.dialog.open(ShareAppDialog, {
      width: "500px",
      data,
    });
  }
}
