import { Injectable } from "@angular/core";
import Peer from "peerjs";
import { Observable, fromEvent } from "rxjs";
import { Store } from "@ngrx/store";
// import { clearBoard } from "./board.actions";

/**
 * The idea for this service is to provide the client with a means of
 * sharing it's current board with other and to allow the other users
 * to take actions that will remain stored in the client's database.
 *
 * To reduce complexity we are going to reference the sharing client as
 * the `Server` and the interfacing clients as the `Client`. This is
 * because the clinets are viewing the servers board.
 *
 * Upon initalization the Server needs to send it's entire database
 * of actions to all the clients. Each client needs to take these
 * actions and pause their own boards.
 */

@Injectable()
export class ShareService {
  static createPeer() {
    return new Peer(null, { debug: 2 });
  }

  public peer: Peer;
  public id: Observable<string>;
  private server: any;

  public get hasServer() {
    return !!this.server;
  }

  constructor(private store: Store<{ board: any }>) {}

  public init(share) {
    if (!this.peer) {
      this.peer = ShareService.createPeer();
      this.id = fromEvent(this.peer, "open");

      this.peer.on("open", function (id) {
        console.log("My peer ID is: " + id);
      });

      this.peer.on("connection", (conn) => {
        // receving connection
        if (!this.server) {
          share.form.patchValue({ code: conn.peer });
          this.connect(share);

          conn.on("open", () => {
            conn.on("data", (action) => {
              console.log("Received", action);

              this.store.dispatch(action);
            });
          });
        }
      });
    }
  }

  public connect(share) {
    this.server = this.peer.connect(share.value.code);

    setTimeout(() => {
      this.sync();
    }, 2000);
  }

  public sync() {
    const actions = JSON.parse(
      window.localStorage.getItem("workspace.actions") || "[]"
    );

    // this.server.send(clearBoard());
    actions.forEach((action) => {
      this.server.send(action);
    });
  }

  public send(action: any) {
    this.server.send(action);
  }
}
