import { Component, ElementRef, ViewChild, Inject } from "@angular/core";
import { interval } from "rxjs";
import { filter } from "rxjs/operators";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NgForm } from "@angular/forms";

export type DialogData = any;

@Component({
  selector: "app-relax",
  template: `
    <section class="container">
      <h3>Meditation Timer</h3>
      <div class="row">
        <form #timeKeeper="ngForm">
          <mat-radio-group
            [ngModel]="'300'"
            aria-label="Select an option"
            name="time"
          >
            <mat-radio-button value="60">1 Minute</mat-radio-button>
            <mat-radio-button value="300">5 Minutes</mat-radio-button>
            <mat-radio-button value="600">10 Minutes</mat-radio-button>
            <mat-radio-button value="900">15 Minutes</mat-radio-button>
          </mat-radio-group>
        </form>
      </div>
      <div class="row">
        <mat-spinner
          mode="determinate"
          *ngIf="timer"
          [value]="((timer || pausedTime) / timeKeeper.form.value.time) * 100"
        >
        </mat-spinner>
        <mat-spinner mode="determinate" *ngIf="!timer" [value]="100">
        </mat-spinner>
        <span class="time-left"
          >{{ timer || pausedTime || timeKeeper.form.value.time }} Seconds</span
        >
        <button
          cdkFocusInitial
          mat-raised-button
          *ngIf="!timer"
          (click)="start(timeKeeper)"
          color="primary"
          type="button"
        >
          Start
        </button>
        <button
          mat-raised-button
          *ngIf="timer"
          (click)="pause()"
          color="accent"
          type="button"
        >
          Pause
        </button>
      </div>
    </section>
    <audio #audio>
      <source src="./assets/Gong_or_bell_vibrant.ogg" type="audio/ogg" />
    </audio>
  `,
  styles: [
    `
      mat-radio-group {
        display: flex;
        flex-direction: column;
      }

      mat-radio-group mat-radio-button {
        margin: 5px;
      }

      .container {
        margin-top: 0px;
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
      }

      .row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: calc(100% / 2);
      }

      mat-spinner {
        margin: 0 auto;
      }
      button,
      .time-left {
        text-align: center;
        margin-top: 10px;
      }
    `,
  ],
})
export class RelaxDialog {
  @ViewChild("audio", { static: true })
  public audioPlayer: ElementRef;

  @ViewChild("timeKeeper", { static: true })
  public timeKeeper: NgForm;

  public timer = 0;
  public pausedTime = 0;
  public timer$ = interval(1000)
    .pipe(filter(() => !!this.timer))
    .subscribe(() => {
      this.timer--;

      if (!this.timer && !this.pausedTime) {
        this.playGong();
      }
    });

  constructor(
    public dialogRef: MatDialogRef<RelaxDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  public start({ form }) {
    if (!this.pausedTime) {
      this.playGong();

      this.timer = parseInt(form.value.time);
    } else {
      this.timer = this.pausedTime;
      this.pausedTime = 0;
    }
  }

  public pause() {
    this.pausedTime = this.timer;
    this.timer = 0;
  }

  public stop() {
    this.pausedTime = 0;
    this.timer = 0;
  }

  public playGong() {
    this.audioPlayer.nativeElement.play();

    setTimeout(() => {
      this.audioPlayer.nativeElement.pause();
      this.audioPlayer.nativeElement.load();
    }, 8000);
  }

  public ngOnDestroy() {
    this.timer$.unsubscribe();
  }
}
