import { createAction, props } from "@ngrx/store";
import {
  CreateBoardAction,
  CreateTaskAction,
  UpdateTaskAction,
  CreateRowAction,
  MoveTaskAction,
  RemoveTaskAction,
  RemoveBoardAction,
} from "./board.interface";

export const createBoard = createAction(
  `[Workspace] Create Board`,
  props<CreateBoardAction>()
);

export const removeBoard = createAction(
  `[Workspace] Remove Board`,
  props<RemoveBoardAction>()
);

export const createRow = createAction(
  `[Workspace] Create Row`,
  props<CreateRowAction>()
);

export const createTask = createAction(
  `[Workspace] Create Task`,
  props<CreateTaskAction>()
);

export const updateTask = createAction(
  `[Workspace] Update Task`,
  props<UpdateTaskAction>()
);

export const moveTask = createAction(
  `[Workspace] Move Task`,
  props<MoveTaskAction>()
);

export const removeTask = createAction(
  `[Workspace] Remove Task`,
  props<RemoveTaskAction>()
);
