import { createReducer, on } from "@ngrx/store";
import produce from "immer";
import { denoteSetting } from "./settings.actions";
import { Setting } from "./settings.interface";

const settings: Setting[] = [
  {
    section: "User defaults",
    name: "Default board",
    description: "",
    type: "Selector",
    options: [{ name: "Task Tracker" }],
    value: 0,
  },
];

const _settingsReducer = createReducer(
  settings,
  on(
    denoteSetting,
    produce((state, action) => {
      const setting = state.find(
        (setting) =>
          setting.section === action.section && setting.name === action.name
      );

      if (setting) {
        setting.value = action.value;
      }
    })
  )
);

export const settingsReducer = (state, action) => {
  return _settingsReducer(state, action);
};
