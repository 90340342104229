import { v4 as uuid } from "uuid";
import { Component, ViewChild, Inject, HostListener } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatDialog } from "@angular/material/dialog";
import { BoardEffects } from "./board.effects";
import { CreateTaskDialog } from "../dialogs/create-task.dialog";
import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { map } from "rxjs/operators";
import { Board, Row } from "./board.interface";
import {
  createTask,
  moveTask,
  updateTask,
  removeTask,
  createBoard,
  createRow,
  removeBoard,
} from "./board.actions";
import { CreateBoardDialog } from "../dialogs/create-board.dialog";
import { AboutAppDialog } from "../dialogs/about.dialog";
import { RelaxDialog } from "../dialogs/relax.dialog";
import { SelectorSetting, Setting } from "../settings/settings.interface";

@Component({
  selector: "app-board",
  templateUrl: "./board.component.html",
  styleUrls: ["./board.component.scss"],
})
export class BoardComponent {
  public boards$ = this.store.pipe(select("boards"));
  public board$ = this.store.pipe(
    map(({ boards, settings }) => {
      const setting = settings.find(
        (setting) => setting.name === "Default board"
      ) as SelectorSetting;
      return boards[setting.value];
    })
  );

  @ViewChild("contextMenuTrigger", { static: true })
  public contextMenu: MatMenuTrigger;

  public contextMenuPosition = { x: "0px", y: "0px" };

  constructor(
    public dialog: MatDialog,
    private store: Store<{ boards: Board[]; settings: Setting[] }>,
    public boardService: BoardEffects,
    @Inject("slugify") public slugify: any
  ) {}

  public typeToColor(type: string) {
    return {
      bug: "#f44336",
      feature: "#9c27b0",
      undefined: "white",
    }[type];
  }

  @HostListener("document:contextmenu", ["$event"])
  public preventContextMenu(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  public openContextMenu(event: MouseEvent, boards, board, row, rows, item?) {
    this.preventContextMenu(event);
    this.contextMenuPosition.x = event.clientX + "px";
    this.contextMenuPosition.y = event.clientY + "px";
    this.contextMenu.menuData = { boards, board, rows, row, item };
    this.contextMenu.menu.focusFirstItem("mouse");
    this.contextMenu.openMenu();
  }

  public navigateTo(board) {
    this.board$ = this.store.pipe(
      select("boards"),
      map((boards) => {
        return boards.find((_board) => _board.id === board.id);
      })
    );
  }

  public selectBoard(event) {
    this.board$ = this.store.pipe(
      select("boards"),
      map((boards) => {
        return boards.find((board) => board.id === event.target.value);
      })
    );
  }

  public openAboutApp() {
    this.dialog.open(AboutAppDialog, {
      width: "500px",
      data: {},
    });
  }

  public openRelax() {
    const data = {};
    this.dialog.open(RelaxDialog, {
      width: "500px",
      height: "300px",
      data,
    });
  }

  public openCreateBoardDialog() {
    const dialogRef = this.dialog.open(CreateBoardDialog, {
      width: "750px",
      data: { item: { value: "" } },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result || !result.item) return;

      const boardId = uuid();
      this.store.dispatch(
        createBoard({
          id: boardId,
          name: result.item.name,
        })
      );

      this.store.dispatch(
        createRow({
          boardId,
          row: { id: uuid(), name: "Todo" },
        })
      );

      this.store.dispatch(
        createRow({
          boardId,
          row: { id: uuid(), name: "In Progress" },
        })
      );

      this.store.dispatch(
        createRow({
          boardId,
          row: { id: uuid(), name: "Review" },
        })
      );

      this.store.dispatch(
        createRow({
          boardId,
          row: { id: uuid(), name: "Done" },
        })
      );
    });
  }

  // board, row, item, create
  public openDialog(board: any, row: any, item?: any, create = true): void {
    const dialogRef = this.dialog.open(CreateTaskDialog, {
      width: "750px",
      data: { create, row, item: item || { value: "" }, rows: board.rows },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!item && result && result.create) {
        this.store.dispatch(
          createTask({
            boardId: board.id,
            rowId: row.id,
            task: result.item,
          })
        );
      } else if (item && result && result.remove) {
        this.removeTask(board, row, result.item);
      } else if (item && result && !result.created) {
        this.store.dispatch(
          updateTask({
            rowId: row.id,
            boardId: board.id,
            task: Object.assign({ id: item.id }, result.updated),
          })
        );
      }
    });
  }

  public toggleTaskFlag(board, row, item?) {
    this.store.dispatch(
      updateTask({
        rowId: row.id,
        boardId: board.id,
        task: { id: item.id, flagged: !item.flagged },
      })
    );
  }

  public toggleTaskVisibility(board, row, item?) {
    if (!item) {
      row.tasks.forEach((task) => {
        this.store.dispatch(
          updateTask({
            rowId: row.id,
            boardId: board.id,
            task: Object.assign({ id: task.id }, { hidden: true }),
          })
        );
      });
    } else {
      this.store.dispatch(
        updateTask({
          rowId: row.id,
          boardId: board.id,
          task: Object.assign({ id: item.id }, { hidden: !item.hidden }),
        })
      );
    }
  }

  public removeTask(board, row, item) {
    this.store.dispatch(
      removeTask({
        boardId: board.id,
        rowId: row.id,
        taskId: item.id,
      })
    );
  }

  public moveTask(pastBoard, board, pastRow, row, item) {
    this.contextMenu.closeMenu();
    this.store.dispatch(
      moveTask({
        taskId: item.id,
        to: { rowId: row.id, boardId: board.id },
        from: { rowId: pastRow.id, boardId: pastBoard.id },
      })
    );
  }

  public drop(event: CdkDragDrop<Row>, board: Board, row: Row) {
    const fromRow = event.previousContainer.data;

    const from = {
      boardId: board.id,
      rowId: board.rows.find((_row) => _row.name === fromRow.name).id,
      index: event.previousIndex,
    };

    const to = {
      boardId: board.id,
      rowId: row.id,
      index: event.currentIndex,
    };

    this.store.dispatch(
      moveTask({
        taskId: event.previousContainer.data.tasks[event.previousIndex].id,
        to,
        from,
      })
    );
  }

  public deleteBoard(board, boards) {
    const _boards = boards.filter((_board) => _board.id !== board.id);

    this.store.dispatch(
      removeBoard({
        boardId: board.id,
      })
    );

    this.navigateTo(_boards[0]);
  }
}
