import { Component, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NgForm } from "@angular/forms";

export type DialogData = any;

@Component({
  selector: "dialog-create-task",
  styles: [
    `
      button {
        float: right;
        margin: 5px;
      }
    `,
    `
      .buttons {
        width: 100%;
      }
    `,
    `
      mat-form-field {
        width: 100%;
      }
    `,
    `
      .container {
        margin-top: 0px;
        display: flex;
        flex-direction: row;
        height: inherit;
      }
      .content {
        flex: 3;
        margin: 10px;
      }
      .actions {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin: 10px;
      }
      .actions button {
      }
    `,
  ],
  template: `
    <form
      #task="ngForm"
      class="container"
      (keydown)="onKeyboard($event)"
      (submit)="update(task)"
    >
      <section class="content">
        <mat-form-field>
          <mat-label>{{ data.row.name }} Task</mat-label>
          <textarea
            matTextareaAutosize
            ngModel
            name="title"
            spellcheck="false"
            matInput
          ></textarea>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Description</mat-label>
          <textarea
            matTextareaAutosize
            cdkAutosizeMinRows="8"
            ngModel
            name="description"
            spellcheck="false"
            matInput
          ></textarea>
        </mat-form-field>
        <br *ngIf="!data.create && data.item.created && !data.item.updated" />
        <div *ngIf="!data.create && data.item.created && !data.item.updated">
          Created on {{ data.item.created | date: "full" }}
        </div>
        <br *ngIf="!data.create && data.item.updated" />
        <div *ngIf="!data.create && data.item.updated">
          Last updated on {{ data.item.updated | date: "full" }}
        </div>
      </section>
      <section class="actions">
        <button
          *ngIf="!data.create"
          [disabled]="!task.dirty"
          type="submit"
          [mat-dialog-close]="data"
          mat-raised-button
          color="primary"
        >
          Save
        </button>
        <button
          *ngIf="data.create"
          type="submit"
          [mat-dialog-close]="data"
          mat-raised-button
          color="primary"
        >
          Create
        </button>
        <button
          *ngIf="!data.create"
          (click)="delete()"
          [mat-dialog-close]="data"
          mat-raised-button
          color="warn"
        >
          Delete
        </button>
        <br />
        <mat-form-field>
          <mat-label>Status</mat-label>
          <mat-select disabled [(value)]="data.row.name">
            <mat-option *ngFor="let _row of data.rows" [value]="_row.name">
              {{ _row.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Type</mat-label>
          <mat-select ngModel name="type">
            <mat-option>
              None
            </mat-option>
            <mat-option value="bug">
              Bug
            </mat-option>
            <mat-option value="feature">
              Feature
            </mat-option>
            <mat-option value="story">
              Story
            </mat-option>
            <mat-option value="spike">
              Research Spike
            </mat-option>
            <mat-option value="refactor">
              Refactor
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Points</mat-label>
          <mat-select ngModel name="points">
            <mat-option value="0">
              None
            </mat-option>
            <mat-option value="1">
              1 Point
            </mat-option>
            <mat-option value="2">
              2 Points
            </mat-option>
            <mat-option value="3">
              3 Points
            </mat-option>
            <mat-option value="5">
              5 Points
            </mat-option>
            <mat-option value="8">
              8 Points
            </mat-option>
            <mat-option value="13">
              13 Points
            </mat-option>
          </mat-select>
        </mat-form-field>
        <br />
        <mat-slide-toggle ngModel name="hidden">Hidden</mat-slide-toggle>
        <br />
        <mat-slide-toggle ngModel name="flagged">Flagged</mat-slide-toggle>
      </section>
    </form>
  `,
})
export class CreateTaskDialog {
  @ViewChild("task", { static: true })
  private task: NgForm;

  constructor(
    public dialogRef: MatDialogRef<CreateTaskDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    @Inject("window") private window: Window,
    @Inject("uuid4") private uuid4: any
  ) {}

  public ngAfterViewInit() {
    if (this.data && this.data.item) {
      this.window.setTimeout(() => {
        this.task.form.patchValue(this.data.item);
      });
    }
  }

  public onKeyboard(event: KeyboardEvent) {
    if (event.shiftKey && event.key === "Enter" && !this.data.create) {
      event.preventDefault();
      // this.update(this.task);
      // this.dialogRef.close(this.data);
    }
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public update(task) {
    if (this.data.create) {
      this.data.item = task.value;
      this.data.item.created = new Date();
      this.data.item.id = this.uuid4();
    } else {
      // TODO: Only update if there are actually changes
      // otherwise just close the model.
      this.data.updated = task.value;
      this.data.updated.updated = new Date();
    }
  }

  public delete() {
    this.data.remove = true;
  }
}
