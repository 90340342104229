import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { switchMap, withLatestFrom } from "rxjs/operators";
import { removeBoard } from "../board/board.actions";
import { denoteSetting } from "./settings.actions";

@Injectable()
export class SettingsEffects {
  public resetDefaultBoardOnDelete = createEffect(
    () =>
      this.actions$.pipe(
        ofType(removeBoard),
        withLatestFrom(this.store),
        switchMap(([action, store]) => {
          const setting = store.settings.find(
            (setting) =>
              setting.section === "User defaults" &&
              setting.name === "Default board"
          );

          if (store.boards.length <= setting.value) {
            return of(
              denoteSetting({
                section: "User defaults",
                name: "Default board",
                value: 0,
              })
            );
          } else {
            return of({ type: "noop" });
          }
        })
      ),
    { dispatch: true }
  );

  constructor(
    private actions$: Actions,
    private store: Store<{ settings: any; boards: any }>
  ) {}
}
