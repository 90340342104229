import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { BehaviorSubject, from } from "rxjs";
import {
  tap,
  skipUntil,
  filter,
  switchMap,
  shareReplay,
  delay,
} from "rxjs/operators";
// import { clearBoard } from "./board.actions";

@Injectable()
export class BoardEffects {
  private _loaded = new BehaviorSubject(false);

  public readonly loaded = this._loaded.pipe(delay(1200), shareReplay());

  public finishLoad = createEffect(
    () =>
      this.actions$.pipe(
        ofType("[Board] Initalization Finished"),
        tap(() => {
          this._loaded.next(true);
        })
      ),
    { dispatch: false }
  );

  /**
   * This effect
   */
  public loadState = createEffect(
    () =>
      this.actions$.pipe(
        ofType("@ngrx/effects/init"),
        switchMap(() => {
          const actions = JSON.parse(
            window.localStorage.getItem("workspace.actions") || "[]"
          );

          return from([...actions, { type: "[Board] Initalization Finished" }]);
        })
      ),
    {
      dispatch: true,
    }
  );

  public saveState = createEffect(
    () =>
      this.actions$.pipe(
        filter(({ type }) => type !== "[Board] Initalization Finished"),
        skipUntil(this.loaded.pipe(filter((value) => value))),
        tap((action) => {
          let actions;
          actions = JSON.parse(
            window.localStorage.getItem("workspace.actions") || "[]"
          );

          actions.push(action);

          window.localStorage.setItem(
            "workspace.actions",
            JSON.stringify(actions)
          );
        })
      ),
    {
      dispatch: false,
    }
  );

  constructor(private actions$: Actions) {}
}
