import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as pkgJson from "../../../../../package.json";
import { environment } from "../../environments/environment";

export type DialogData = any;

@Component({
  selector: "dialog-about-app",
  styles: [],
  template: `
    <h2>Community Workspace</h2>
    <p>Version {{ pkgJson.version }} ({{ environment.buildName }})</p>
  `,
})
export class AboutAppDialog {
  public readonly environment = environment;
  public readonly pkgJson = (pkgJson as any).default;

  constructor(
    public dialogRef: MatDialogRef<AboutAppDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  public onNoClick(): void {
    this.dialogRef.close();
  }
}
