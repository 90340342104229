import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Board } from "../board/board.interface";
import { Setting } from "../settings/settings.interface";

@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.scss"],
})
export class ReportsComponent implements OnInit {
  public actions = JSON.parse(
    window.localStorage.getItem("workspace.actions") || "[]"
  );

  constructor(private store: Store<{ boards: Board[]; settings: Setting[] }>) {}

  ngOnInit() {}
}
