import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { BehaviorSubject, of } from "rxjs";
import {
  debounce,
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  shareReplay,
  skipUntil,
  switchMap,
  switchMapTo,
  tap,
  throttleTime,
  withLatestFrom,
} from "rxjs/operators";
import { moveTask } from "./board/board.actions";
import { Board } from "./board/board.interface";
import { denoteSetting } from "./settings/settings.actions";

@Injectable()
export class SnackbarEffects {
  private _loaded = new BehaviorSubject(false);
  public readonly loaded = this._loaded.pipe(delay(1400), shareReplay());

  constructor(private _snackBar: MatSnackBar, private actions$: Actions) {}

  public finishLoad = createEffect(
    () =>
      this.actions$.pipe(
        ofType("[Board] Initalization Finished"),
        tap(() => {
          this._loaded.next(true);
        })
      ),
    { dispatch: false }
  );

  public settingsSnackbarWatcher = createEffect(
    () =>
      this.actions$.pipe(
        filter(({ type }) => type !== "[Board] Initalization Finished"),
        skipUntil(this.loaded.pipe(filter((value) => value))),
        ofType(denoteSetting),
        tap((action) => {
          this._snackBar.open(`Saved ${action.name.toLowerCase()}`, "", {
            duration: 2000,
          });
        })
      ),
    { dispatch: false }
  );

  public moveSnackbarWatcher = createEffect(() =>
    this.actions$.pipe(
      filter(({ type }) => type !== "[Board] Initalization Finished"),
      skipUntil(this.loaded.pipe(filter((value) => value))),
      ofType(moveTask),
      distinctUntilChanged((x, y) => x.from === y.to && x.taskId === y.taskId),
      switchMap((action) => {
        const snackBarRef = this._snackBar.open(
          "Successfully moved task",
          "Undo",
          {
            duration: 2000,
          }
        );

        return snackBarRef.onAction().pipe(
          switchMapTo(
            of(
              moveTask({
                from: action.to,
                to: action.from,
                taskId: action.taskId,
              })
            )
          )
        );
      })
    )
  );
}
