import { Component, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { map } from "rxjs/operators";
import { Board } from "../board/board.interface";
import { denoteSetting } from "./settings.actions";
import { Setting, SelectorSetting } from "./settings.interface";
import { SettingsService } from "./settings.service";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
  providers: [SettingsService],
})
export class SettingsComponent implements OnInit {
  public settings$ = this.store.pipe(
    map((stores) => {
      let sections: any = {};

      stores.settings.forEach((setting) => {
        let _setting = { ...setting };

        if (setting.name === "Default board") {
          (_setting as SelectorSetting).options = [...(stores.boards || [])];
        }

        sections = {
          ...sections,
          [setting.section]: [...(sections[setting.section] || []), _setting],
        };
      });

      sections = Object.keys(sections).map((key) => {
        return { name: key, settings: sections[key] };
      });

      return sections;
    })
  );

  constructor(
    public settingsService: SettingsService,
    private store: Store<{ settings: Setting[]; boards: Board[] }>
  ) {}

  ngOnInit() {
    this.settings$.subscribe(console.log);
  }

  public setSetting(event, setting, section) {
    console.log(event, setting);
    let value;

    switch (setting.type) {
      case "Slider":
        value = event.checked;
        break;
      case "Selector":
        value = event.value;
        break;
    }

    this.store.dispatch(
      denoteSetting({
        section: section.name,
        name: setting.name,
        value,
      })
    );
  }
}
