import { Component, Inject, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NgForm } from "@angular/forms";

export type DialogData = any;

@Component({
  selector: "dialog-create-board",
  styles: [
    `
      button {
        float: right;
        margin: 5px;
      }
    `,
    `
      .buttons {
        width: 100%;
      }
    `,
    `
      mat-form-field {
        width: 100%;
      }
    `,
    `
      .container {
        margin-top: 0px;
        display: flex;
        flex-direction: row;
        height: inherit;
      }
      .content {
        flex: 3;
        margin: 10px;
      }
      .actions {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin: 10px;
      }
      .actions button {
      }
    `,
  ],
  template: `
    <form
      #board="ngForm"
      class="container"
      (keydown)="onKeyboard($event)"
      (submit)="create(board)"
    >
      <section class="content">
        <mat-form-field>
          <mat-label>Board name</mat-label>
          <textarea matTextareaAutosize ngModel name="name" matInput></textarea>
        </mat-form-field>
      </section>
      <section class="actions">
        <button
          type="submit"
          [mat-dialog-close]="data"
          mat-raised-button
          color="primary"
        >
          Create
        </button>
      </section>
    </form>
  `,
})
export class CreateBoardDialog {
  @ViewChild("board", { static: true })
  private task: NgForm;

  constructor(
    public dialogRef: MatDialogRef<CreateBoardDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    @Inject("window") private window: Window,
    @Inject("uuid4") private uuid4: any
  ) {}

  public ngAfterViewInit() {
    if (this.data && this.data.item) {
      this.window.setTimeout(() => {
        this.task.form.patchValue(this.data.item);
      });
    }
  }

  public onKeyboard(event: KeyboardEvent) {}

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public create(board) {
    this.data.item = board.value;
    this.data.item.id = this.uuid4();
  }
}
