import { Injectable } from "@angular/core";

@Injectable()
export class SettingsService {
  public setSetting(name: string, value: any) {
    const settings = this.getSettings();
    settings[name] = value;
    this.setSettings(settings);
  }

  public getSetting(name: string) {
    const settings = this.getSettings();

    return settings[name];
  }

  public getSettings() {
    return JSON.parse(window.localStorage.getItem("workspace.settings")) || {};
  }

  public setSettings(settings: { [name: string]: any }) {
    window.localStorage.setItem("workspace.settings", JSON.stringify(settings));
  }

  public deleteSetting(name: string) {
    const settings = this.getSettings();
    delete settings[name];
    this.setSettings(settings);
  }
}
