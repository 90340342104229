import { NgModule } from "@angular/core";
import { Routes, RouterModule, ɵEmptyOutletComponent } from "@angular/router";
import { BoardComponent } from "./board/board.component";
import { CalendarComponent } from "./calendar/calendar.component";
import { ReportsComponent } from "./reports/reports.component";
import { SearchComponent } from "./search/search.component";
import { SettingsComponent } from "./settings/settings.component";
import { WikiComponent } from "./wiki/wiki.component";

const routes: Routes = [
  {
    path: "settings",
    component: SettingsComponent,
  },
  {
    path: "search",
    component: SearchComponent,
  },
  {
    path: "calendar",
    component: CalendarComponent,
  },
  {
    path: "reports",
    component: ReportsComponent,
  },
  {
    path: "wiki",
    component: WikiComponent,
  },
  {
    path: "board",
    component: BoardComponent,
  },
  {
    path: "**",
    redirectTo: "board",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
